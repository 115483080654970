<template>
    <div class="menu p-col-3 p-px-0 p-py-0">
        <div class="p-col-12 p-px-0 p-py-0 p-d-flex p-flex-column" style="min-height: 100%">
            <div class="rightPanelMenu">
                <span class="p-buttonset">
                    <Button
                        v-for="button in (distributionId ? allButtons : (formDistributionId ? mainAggregateButtons : aggregateButtons))"
                        :key="button.code"
                        :icon="button.icon"
                        :class="[selectedPanel.code === `${button.code}` ? 'active-section' : '', 'p-button', 'p-component', 'p-button-outlined', 'p-button-black', 'custom-button', 'customFontSize']"
                        @click="changeComponent(button)"
                    />
                    <span class="topbar-badge" v-show="worksheetErrors.length > 0">{{ worksheetErrors.length }}</span>
                </span>
                <span class="p-buttonset">
                    <Button icon="pi pi-ellipsis-h" v-show="this.selectedPanel.code === 'comments'" @click="showCommentMenu" class="p-button-black custom-close custom-menu" />
                    <Button icon="pi pi-times" @click="hideRightPanel" class="p-button-black custom-close" />
                </span>
            </div>
            <Menu id="config_menu" ref="commentMenu" :model="commentMenu" :popup="true" />
            <keep-alive>
                <component
                    :is="currentTabComponent"
                    :distributionId="distributionId"
                    :worksheetId="worksheetId"
                    :groups="groups"
                    :formDistributionPeriods="formDistributionPeriods"
                    :formDistributionOrganizations="formDistributionOrganizations"
                    :worksheetHistory="worksheetHistory"
                    :worksheetErrors="worksheetErrors"
                    :errorGroups="errorGroups"
                    :errorFormStatus="errorFormStatus"
                    :errorStatus="errorStatus"
                    :worksheetComments="worksheetComments"
                    :formTitle="formTitle"
                    :worksheetTitle="worksheetTitle"
                    :canEdit="canEdit"
                    :canReport="canReport"
                    :status="status"
                    @listSelect="listSelect"
                    @sendComment="sendComment"
                    @showCommentDialog="showCommentDialog"
                    @showErrorCell="showErrorCell"
                    @showHistoryVersion="showHistoryVersion"
                    @restore="restore"
                    @download="download"
                ></component>
            </keep-alive>

            <Dialog :visible.sync="addCommentDialog" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 500px">
                <template #header>
                    <h3 class="p-mb-0">{{ commentTitle }}</h3>
                </template>
                <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0 errorPos">
                    <label class="p-text-normal">Комментарий</label>
                    <Textarea v-model="v$.comment.$model"
                          placeholder="Текст комментария"
                          :autoResize="true"
                          rows="1"
                          cols="25"
                          :class="v$.comment.$invalid && submitted ? 'p-error' : ''"
                    />
                    <small v-show="submitted" v-for="error in showErrors(v$.comment)" :key="error.$uid" class="p-error errorPosition">{{ error.$message }}</small>
                </div>
                <template #footer>
                    <div class="p-d-flex p-jc-start">
                        <Button class="p-button" type="button" @click="sendComment">
                            <span class="p-button-label">Сохранить</span>
                            <span class="p-ink"></span>
                        </Button>
                        <Button class="p-button p-button-outlined" @click="closeCommentDialog">
                            <span class="p-button-label black">Отменить</span>
                        </Button>
                    </div>
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import comments from '@/components/dataEntry/comments.vue';
import mistakes from '@/components/dataEntry/mistakes.vue';
import formMistakes from '@/components/dataEntry/formMistakes';
import history from '@/components/dataEntry/history.vue';
import lists from '@/components/dataEntry/lists.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { patchComment, sendComment } from '@/api/form/formsDistributions';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';

export default {
    name: 'rightPanel',
    mixins: [requestToastHandler],
    components: {
        comments,
        mistakes,
        formMistakes,
        history,
        lists,
    },
    props: {
        selectedPanel: {
            require: true,
            type: Object,
        },
        distributionId: {
            require: true,
            type: String,
        },
        aggregateId: {
            require: true,
            type: String,
        },
        formDistributionId: {
            require: true,
            type: String,
        },
        worksheetId: {
            require: true,
            type: String,
        },
        groups: {
            require: true,
            type: Array,
        },
        worksheetHistory: {
            require: true,
            type: Array,
        },
        worksheetErrors: {
            require: true,
            type: Array,
        },
        errorGroups: {
            required: true,
            type: Array,
        },
        errorFormStatus: {
            type: String,
        },
        errorStatus: {
            type: String,
        },
        worksheetComments: {
            require: true,
            type: Array,
        },
        formDistributionPeriods: {
            type: Array,
        },
        formDistributionOrganizations: {
            type: Array,
        },
        formTitle: {
            require: true,
            type: String,
        },
        worksheetTitle: {
            require: true,
            type: String
        },
        canEdit: {
            require: true,
            type: Boolean
        },
        canReport: {
            require: true,
            type: Boolean
        },
        status: {
            require: true,
            type: String
        }
    },
    emits: [
        'changeComponent',
        'listSelect',
        'showCommentDialog',
        'loadingChange',
        'showErrorCell',
        'showHistoryVersion',
        'restore',
        'download'
    ],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            groupTitle: '',
            groupSort: null,
            groupColor: null,
            listTitle: '',
            listSort: null,
            columnCount: null,
            rowCount: null,
            allButtons: [
                { label: 'Комментарии', icon: 'icon custom-comment', code: 'comments' },
                { label: 'Ошибки листа', icon: 'pi pi-exclamation-triangle', code: 'mistakes' },
                { label: 'Ошибки формы', icon: 'pi pi-exclamation-circle', code: 'formMistakes' },
                { label: 'История', icon: 'icon custom-history-icon', code: 'history' },
                { label: 'Листы', icon: 'icon custom-list-icon', code: 'lists' },
            ],
            aggregateButtons: [
                { label: 'Листы', icon: 'icon custom-list-icon', code: 'lists' },
                { label: 'Ошибки листа', icon: 'pi pi-exclamation-triangle', code: 'mistakes' },
                { label: 'Ошибки формы', icon: 'pi pi-exclamation-circle', code: 'formMistakes' },
            ],
            mainAggregateButtons: [
                { label: 'Комментарии', icon: 'icon custom-comment', code: 'comments' },
                { label: 'Ошибки листа', icon: 'pi pi-exclamation-triangle', code: 'mistakes' },
                { label: 'Ошибки формы', icon: 'pi pi-exclamation-circle', code: 'formMistakes' },
                { label: 'Листы', icon: 'icon custom-list-icon', code: 'lists' },
            ],
            commentMenu: [
                {
                    label: 'Добавить комментарий',
                    icon: 'pi pi-comment',
                    command: () => {
                        this.showCommentDialog();
                    },
                },
            ],
            addCommentDialog: false,
            comment: '',
            selectedComment: '',
            commentMode: '',
            commentTitle: 'Добавить новый комментарий',
            submitted: false,
        };
    },
    validations() {
        return {
            comment: {
                required: { ...required, $message: 'Поле обязательно к заполнению' },
            },
        };
    },
    methods: {
        hideRightPanel() {
            this.$emit('hideRightPanel');
        },
        changeComponent(button) {
            this.$emit('changeComponent', button);
        },
        listSelect(data) {
            this.$emit('listSelect', data);
        },
        showErrorCell(data) {
            this.$emit('showErrorCell', data);
        },
        showHistoryVersion(data) {
            this.$emit('showHistoryVersion', data);
        },
        restore(data) {
            this.$emit('restore', data);
        },
        download(data) {
            this.$emit('download', data);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        generateGroupData(groupId, header, sort, lists) {
            return {
                currentGroupId: groupId,
                attributes: {
                    type: 'form-template-group',
                    id: groupId,
                    attributes: {
                        name: header,
                        slug: 'string1',
                        sort: sort || 0,
                    },
                },
                worksheets: lists.map((worksheet) => {
                    return {
                        type: 'form-template-worksheet',
                        id: worksheet.listId,
                    };
                }),
            };
        },
        showCommentMenu(event) {
            this.$refs.commentMenu.toggle(event);
        },
        hideCommentMenu() {
            this.$refs.commentMenu.hide();
        },
        showCommentDialog(commentId = '', mode = 'CREATE', comment = '') {
            this.addCommentDialog = true;
            this.selectedComment = commentId;
            this.commentMode = mode;
            this.comment = mode === 'CREATE' ? '' : comment;
            this.commentTitle = mode === 'CREATE' ? 'Добавить новый комментарий' : 'Редактировать комментарий';
        },
        async sendComment() {
            this.submitted = true;
            if (!this.v$.comment.$invalid) {
                if (this.commentMode === 'CREATE') {
                    const data = {
                        data: {
                            type: 'comment',
                            id: uuidv4(),
                            attributes: {
                                message: this.comment,
                                createdAt: '',
                            },
                        },
                    };

                    if (this.selectedComment) {
                        data.data.relationships = {
                            thread: {
                                data: {
                                    type: 'comment',
                                    id: this.selectedComment,
                                },
                            },
                        };
                    }

                    try {
                        const result = await sendComment(this.distributionId || this.formDistributionId, this.worksheetId, data);

                        if (result.message) {
                            this.$requestError(result.message);
                            return;
                        }

                        const commentData = {
                            date: result.data.attributes.createdAt,
                            id: result.data.id,
                            name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
                            message: result.data.attributes.message,
                            timeStamp: +new Date(result.data.attributes.createdAt),
                            userId: this.currentUser.id
                        };
                        this.worksheetComments.unshift(commentData);

                        this.closeCommentDialog();
                    } catch (error) {
                        this.$emit('loadingChange', false);
                        this.$requestError(error.message);
                    }
                }
                if (this.commentMode === 'EDIT') {
                    const data = {
                        data: {
                            type: 'comment',
                            id: this.selectedComment.id,
                            attributes: {
                                message: this.comment,
                            },
                        },
                    };

                    try {
                        const { data: result } = await patchComment(this.selectedComment, data);

                        const commentData = {
                            date: result.data.attributes.updatedAt,
                            id: result.data.id,
                            name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
                            message: result.data.attributes.message,
                            timeStamp: +new Date(result.data.attributes.updatedAt),
                            userId: this.currentUser.id,
                        };

                        let commentIndex = this.worksheetComments.findIndex(item => item.id === result.data.id);
                        this.worksheetComments.splice(commentIndex, 1);
                        this.worksheetComments.unshift(commentData);

                        this.closeCommentDialog();
                    } catch (error) {
                        this.$emit('loadingChange', false);
                        this.$requestError(error.message);
                    }
                }
            }
        },
        closeCommentDialog() {
            this.comment = '';
            this.submitted = false;
            this.addCommentDialog = false;
        },
    },
    computed: {
        currentTabComponent() {
            return `${this.selectedPanel.code}`;
        },
        ...mapGetters('auth', ['currentUser']),
    },
};
</script>

<style lang="scss" scoped>
.menu {
    width: 255px;
    background: #ffffff;
    color: black;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    animation: all 1s;
    max-height: 100vh;
    overflow-y: auto;
}

.rightPanelMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 38px;
    background: #f5f5f5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 100;
}
::v-deep .p-buttonset {
    display: flex;
    align-items: center;
    .pi-exclamation-triangle:before,
    .pi-exclamation-circle:before,
    .pi-replay:before,
    .pi-list:before,
    .pi-pencil:before {
        font-size: 18px;
        color: #495058;
    }
    .p-button.p-button-outlined:enabled:hover {
        background: #e0e0e0 !important;
        color: #272727 !important;
        border: 0 !important;
    }
}

.p-button-black {
    color: black;
}
.p-buttonset .custom-button {
    border-radius: 0;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid #c4c4c4;
    width: 38px;
    height: 38px;
}

.custom-close {
    border-radius: 0;
    background-color: transparent;
    border: none;
    width: 38px;
    height: 38px;
}
.custom-close:hover {
    background: #e0e0e0;
    color: #495058;
}
.custom-menu {
    color: black;
    width: 24px;
    height: 24px;
    border-radius: 50% !important;
    border-color: #328036;
}
.custom-menu:hover {
    border-radius: 50% !important;
    background: rgba(135, 148, 163, 0.25);
}
.active-section {
    background: #e0e0e0;
}
.country-item {
    display: flex;
    img {
        width: 17px;
        margin-right: 0.5rem;
    }
}
::v-deep .p-panel {
    .p-panel-header {
        padding: 0.5rem 0 0.5rem 0.5rem;
        .p-panel-title {
            font-weight: 500;
        }
    }

    .p-panel-content {
        padding: 0;
    }
    .p-panel-icons {
        min-width: 60px;
    }
    .p-link {
        margin-right: 0 !important;
        border-radius: 26px;
        .p-panel-header-icon {
            .pi {
                font-size: 1.3rem !important;
            }
        }
    }
    .userContainer:hover {
        .p-link {
            background: rgba(73, 80, 88, 0.25);
            padding: 2px;
        }
    }
    .p-link:hover {
        background: rgba(73, 80, 88, 0.25);
    }
    .p-panel .p-panel-header .p-panel-header-icon:focus {
        box-shadow: none;
    }
    .p-panel-header-icon {
        box-shadow: none;
    }
    .p-panel-header-icon:hover {
        border-radius: 50%;
        background: rgba(73, 80, 88, 0.25);
    }
}

::v-deep .p-dialog {
    .p-dialog-header {
        padding: 24px;
        .p-dialog-header-icon:focus {
            box-shadow: none;
        }
    }
    .p-dialog-content {
        padding: 0 24px;
        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
        }
        .p-field {
            margin-bottom: 0;
        }
        .errorPos {
            position: relative;
        }
        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 4px;
        }
    }

    .p-inputtext {
        padding: 8px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
    }
    .p-dialog-footer {
        padding: 0 24px 24px 24px;
        button {
            margin: 0 12px 0 0;
        }
    }
    .p-pr8 {
        padding-right: 8px;
    }
    .p-pl8 {
        padding-left: 8px;
    }
}
::v-deep .customFontSize {
    .custom-history-icon:before {
        font-size: 19px !important;
    }
    .custom-list-icon:before {
        font-size: 17px !important;
    }
    .custom-formula-icon:before {
        font-size: 18px !important;
    }
}
::v-deep .p-menu {
    width: 208px;
}
::v-deep .p-menu-list {
    .p-menuitem-icon {
        font-size: 16px;
    }
}
.customWidth {
    width: 208px !important;
}
.p-pr8 {
    margin-right: 16px;
}
.p-buttonset .topbar-badge {
    position: fixed;
    display: block;
    right: 228px;
    top: 76px;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: .714rem;
    border-radius: 50%;
    font-weight: 600;
    background: #e34d25;
    color: #ffffff;
    text-align: center;
    z-index: 1000;

    @media screen and (max-width: 1240px) {
        position: sticky;
        margin-top: -17px;
        right: 207px;
        top: 0;

    }
}
</style>
