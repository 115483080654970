import { render, staticRenderFns } from "./formMistakes.vue?vue&type=template&id=471ecfc6&scoped=true&"
import script from "./formMistakes.vue?vue&type=script&lang=js&"
export * from "./formMistakes.vue?vue&type=script&lang=js&"
import style0 from "./formMistakes.vue?vue&type=style&index=0&id=471ecfc6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "471ecfc6",
  null
  
)

export default component.exports